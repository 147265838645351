// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Logo */
img {
    height: 100%;
    width: 100%;
}

/* Auto layout */
.logo_container {
    
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
    text-decoration: none;
    gap: 10px;

    position: relative;
    width: 190px;
    height: 42px;  
}

.logo_img {
    /* Logo Image */
    width: 32px;
    height: 42px;

    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%; 
}

.logo_title {

    width: 128px;
    height: 22px;
    white-space: nowrap;
    overflow: hidden;

    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    /* identical to box height */
    letter-spacing: 0.145em;
    color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/components/logo/logo.css"],"names":[],"mappings":"AAAA,SAAS;AACT;IACI,YAAY;IACZ,WAAW;AACf;;AAEA,gBAAgB;AAChB;;IAEI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,SAAS;;IAET,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,YAAY;;IAEZ,QAAQ;IACR,SAAS;IACT,OAAO;IACP,UAAU;AACd;;AAEA;;IAEI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;;IAEhB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;;IAEjB,4BAA4B;IAC5B,uBAAuB;IACvB,cAAc;AAClB","sourcesContent":["/* Logo */\nimg {\n    height: 100%;\n    width: 100%;\n}\n\n/* Auto layout */\n.logo_container {\n    \n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding: 0px 10px;\n    text-decoration: none;\n    gap: 10px;\n\n    position: relative;\n    width: 190px;\n    height: 42px;  \n}\n\n.logo_img {\n    /* Logo Image */\n    width: 32px;\n    height: 42px;\n\n    left: 0%;\n    right: 0%;\n    top: 0%;\n    bottom: 0%; \n}\n\n.logo_title {\n\n    width: 128px;\n    height: 22px;\n    white-space: nowrap;\n    overflow: hidden;\n\n    font-family: 'Jost';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 15px;\n    line-height: 22px;\n\n    /* identical to box height */\n    letter-spacing: 0.145em;\n    color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
