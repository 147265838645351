import React from 'react'
import NavbarItem from './NavbarItem'
import Logo from '../logo/Logo'

const Navbar = () => {
  return (
    <div className='navbar_container'>

        <Logo />

    </div>
  )
}

export default Navbar