// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
To apply the font above:
    font-family: 'Jost', sans-serif;
*/`, "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAEA;;;CAGC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');\n/* \nTo apply the font above:\n    font-family: 'Jost', sans-serif;\n*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
