// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Slideshow */

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 500px;
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  }
  
  .slide {
    display: inline-block;
  
    height: auto;
    width: 100%;
    border-radius: 0px;
  }
  
  /* Buttons */
  
  .slideshowDots {
    text-align: center;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  
    cursor: pointer;
    margin: 15px 7px 0px;
  
    background-color: #c4c4c4;
  }
  
  .slideshowDot.active {
    background-color: #6a0dad;
  }
  `, "",{"version":3,"sources":["webpack://./src/containers/Auto-play Slideshow/slideshow.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;IACI,cAAc;IACd,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,qBAAqB;;IAErB,YAAY;IACZ,WAAW;IACX,kBAAkB;EACpB;;EAEA,YAAY;;EAEZ;IACE,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,WAAW;IACX,kBAAkB;;IAElB,eAAe;IACf,oBAAoB;;IAEpB,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* Slideshow */\n\n.slideshow {\n    margin: 0 auto;\n    overflow: hidden;\n    max-width: 500px;\n  }\n  \n  .slideshowSlider {\n    white-space: nowrap;\n    transition: ease 1000ms;\n  }\n  \n  .slide {\n    display: inline-block;\n  \n    height: auto;\n    width: 100%;\n    border-radius: 0px;\n  }\n  \n  /* Buttons */\n  \n  .slideshowDots {\n    text-align: center;\n  }\n  \n  .slideshowDot {\n    display: inline-block;\n    height: 10px;\n    width: 10px;\n    border-radius: 50%;\n  \n    cursor: pointer;\n    margin: 15px 7px 0px;\n  \n    background-color: #c4c4c4;\n  }\n  \n  .slideshowDot.active {\n    background-color: #6a0dad;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
